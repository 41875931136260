import { useState } from "react";
import { t } from "i18n-js";
import { ReportFlaggedContentModal } from "@circle-react/components/SettingsApp/Moderation/ReportFlaggedContentModal";

interface ModerationInfoProps {
  record: { unresolved_flagged_reports_count: number; id: string };
}

export const ModerationInfo = ({ record }: ModerationInfoProps) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const { unresolved_flagged_reports_count } = record;

  if (!unresolved_flagged_reports_count) return null;

  return (
    <>
      <div className="mb-3 flex w-full items-center justify-between rounded bg-yellow-100 px-3 py-2">
        <span className="text-sm font-medium normal-case leading-5">
          {t("flagged_contents.moderation_info.flagged_report", {
            count: unresolved_flagged_reports_count,
          })}
        </span>
        <button
          type="button"
          onClick={openModal}
          className="rounded-full border border-black bg-transparent px-4 py-1 text-sm font-semibold text-black transition hover:bg-yellow-100"
        >
          {t("flagged_contents.moderation_info.review_button")}
        </button>
      </div>
      {isModalOpen && (
        <ReportFlaggedContentModal
          flaggedContentId={record.id}
          flaggedContentType="post"
          isOpen={isModalOpen}
          onClose={closeModal}
          hideDetailsTab
        />
      )}
    </>
  );
};
