import InnerHTML from "dangerously-set-html-content";
import { t } from "i18n-js";
import { DisplayTiptapContent } from "@circle-react/components/CommentsV3/Comment/DisplayTiptapContent";
import { StandaloneCarousel } from "@circle-react/components/Spaces/ImageSpace/FeedView/StandaloneCarousel";
import { Typography } from "@circle-react-uikit/Typography";

const i18nRoot = "settings.moderation.review_flagged_content_modal";

export interface ReportFlaggedContentableProps {
  trixBody?: string;
  tiptapBody?: any;
  isImagePost?: boolean;
  postSlug?: any;
  spaceId?: any;
}

export const ReportFlaggedContentable = ({
  trixBody,
  tiptapBody,
  isImagePost = false,
  postSlug,
  spaceId,
}: ReportFlaggedContentableProps) => {
  if (isImagePost && postSlug && spaceId) {
    return (
      <div className="flex flex-col gap-4">
        {tiptapBody && <DisplayTiptapContent tiptapBody={tiptapBody} />}
        <div className="-mt-2">
          <StandaloneCarousel postSlug={postSlug} spaceId={spaceId} />
        </div>
      </div>
    );
  }

  if (trixBody) {
    return <InnerHTML html={trixBody} />;
  }

  if (tiptapBody) {
    return <DisplayTiptapContent tiptapBody={tiptapBody} />;
  }

  return (
    <Typography.LabelSm color="text-lightest">
      {t([i18nRoot, "empty"])}
    </Typography.LabelSm>
  );
};
