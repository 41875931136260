import classNames from "classnames";
import { t } from "i18n-js";
import type { FlaggedContentReport } from "@circle-react/types";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";
import { getTimeAgo } from "../utils";

export interface ReportsListProps {
  reports?: FlaggedContentReport[];
}

const i18nRoot = "settings.moderation.review_flagged_content_modal";

export const ReportsList = ({ reports = [] }: ReportsListProps) => (
  <div className="flex flex-col gap-6 px-8 py-6">
    {reports.map(
      ({
        id,
        reported_reason_type = "",
        reported_reason_body,
        reported_at = "",
        reporter,
        profanity_filter_matches = "",
      }) => {
        const reporterFullName = [reporter?.first_name, reporter?.last_name]
          .filter(n => n)
          .join(" ");
        return (
          <div key={id} className="flex flex-col gap-3">
            {reporter && (
              <div className="flex items-center gap-4">
                <UserImage
                  name={reporterFullName}
                  src={reporter.avatar_url}
                  size="8"
                />
                <div className="flex flex-col">
                  <Typography.LabelSm weight="semibold">
                    {reporterFullName}
                  </Typography.LabelSm>
                  <Typography.LabelXs color="text-dark">
                    {getTimeAgo(reported_at)}
                  </Typography.LabelXs>
                </div>
              </div>
            )}
            <div
              className={classNames(
                "bg-tertiary flex flex-col gap-1.5 rounded-lg px-4 py-3",
                {
                  "ml-12": !!reporter,
                },
              )}
            >
              {reported_reason_type && (
                <div>
                  <Typography.LabelSm weight="semibold">
                    {t([i18nRoot, "reason"])}
                  </Typography.LabelSm>
                  &nbsp;
                  <Typography.LabelSm data-testid="report-reason-type">
                    {t([i18nRoot, "report_reasons", reported_reason_type], {
                      matches: profanity_filter_matches,
                    })}
                  </Typography.LabelSm>
                </div>
              )}
              {reported_reason_body && (
                <Typography.LabelSm
                  color="text-default"
                  as="p"
                  data-testid="report-reason-body"
                >
                  {reported_reason_body}
                </Typography.LabelSm>
              )}
            </div>
          </div>
        );
      },
    )}
    {reports.length === 0 && (
      <Typography.LabelSm color="text-lightest">
        {t([i18nRoot, "empty"])}
      </Typography.LabelSm>
    )}
  </div>
);
