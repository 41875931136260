import { t } from "i18n-js";
import { useMutation, useQuery, useQueryClient } from "react-query";
import type { ApiError } from "@circle-react/config/CustomErrors";
import type { FlaggedContent } from "@circle-react/types";
import { useToast } from "@circle-react-uikit/ToastV2";
import { useModerationApi } from "../useModerationApi";

const i18nRoot = "settings.moderation.review_flagged_content_modal";

export const useAproveRejectContent = (
  flaggedContentId: string,
  flaggedContentType: "flagged-content" | "post" | "comment",
) => {
  const { success, error } = useToast();
  const { get, approve, reject } = useModerationApi();
  const queryClient = useQueryClient();

  const { data: flaggedContent, isLoading } = useQuery<
    void,
    ApiError,
    FlaggedContent
  >(
    ["flagged-content", flaggedContentId, flaggedContentType],
    () => get(flaggedContentId, flaggedContentType),
    {
      enabled: !!flaggedContentId,
    },
  );

  const effectiveId =
    flaggedContentType === "flagged-content"
      ? flaggedContentId
      : flaggedContent?.id || flaggedContentId;

  const invalidateTableQueries = () => {
    void queryClient.invalidateQueries({ queryKey: ["flagged-content"] });
  };

  const { mutate: approveFlaggedContent, isLoading: isApproving } = useMutation<
    void,
    ApiError,
    void
  >({
    mutationFn: () => approve(String(effectiveId)),
    onSuccess: () => {
      invalidateTableQueries();
      success(t([i18nRoot, "success_approving"]));
    },
    onError: () => error(t([i18nRoot, "error_approving"])),
  });

  const { mutate: rejectFlaggedContent, isLoading: isRejecting } = useMutation<
    void,
    ApiError,
    void
  >({
    mutationFn: () => reject(String(effectiveId)),
    onSuccess: () => {
      invalidateTableQueries();
      success(t([i18nRoot, "success_rejecting"]));
    },
    onError: () => error(t([i18nRoot, "error_rejecting"])),
  });

  return {
    approveFlaggedContent,
    isApproving,
    rejectFlaggedContent,
    isRejecting,
    isLoading,
    flaggedContent,
  };
};
