import { t } from "i18n-js";
import { NavLink } from "react-router-dom";
import type { CommunityMember, Space } from "@circle-react/types";
import { Icon } from "@circle-react-shared/Icon";
import { MiddleDot } from "@circle-react-shared/uikit/MiddleDot";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";
import { CONTENT_TYPES_MAP } from "../constants";
import { getTimeAgo } from "../utils";

const i18nRoot = "settings.moderation.review_flagged_content_modal";

export interface ReportHeaderProps {
  contentType?: string;
  reportedAt?: string;
  reportsCount?: number;
  communityMember?: CommunityMember;
  postSlug?: string;
  postTitle?: string;
  space?: Space;
  onNavigate?: () => void | Promise<void> | Promise<unknown>;
  flaggedContentableId?: number;
}

export const ReportHeader = ({
  communityMember,
  contentType = "",
  reportedAt = "",
  reportsCount = 1,
  postSlug = "",
  postTitle,
  space,
  onNavigate,
  flaggedContentableId,
}: ReportHeaderProps) => {
  const { first_name, last_name, avatar_url, public_uid } =
    communityMember ?? {};
  const fullName = [first_name, last_name].filter(n => n).join(" ");
  const { slug: spaceSlug, name: spaceName } = space ?? {};

  const isPost = contentType.toLowerCase() === CONTENT_TYPES_MAP.POST;
  const isComment = contentType.toLowerCase() === CONTENT_TYPES_MAP.COMMENT;

  const spaceUrl = `/c/${spaceSlug}`;
  const postUrl = `${spaceUrl}/${postSlug}`;
  const communityMemberUrl = `/admin/members/${public_uid}/info`;

  const navLinkProps = {
    onClick: onNavigate,
    className: "flex font-semibold leading-5 text-dark",
  };

  return (
    <div className="flex items-center gap-4 p-8 pb-4">
      {communityMember && (
        <NavLink
          to={communityMemberUrl}
          onClick={onNavigate}
          className="flex self-start"
        >
          <UserImage name={fullName} src={avatar_url} size="10" />
        </NavLink>
      )}

      <div className="flex flex-col gap-1 pr-4">
        <div className="flex flex-wrap space-x-1 space-x-reverse">
          <span className="mr-1">
            {communityMember ? (
              <NavLink to={communityMemberUrl} {...navLinkProps}>
                {fullName}
              </NavLink>
            ) : (
              <Typography.LabelMd>
                {t([i18nRoot, "deleted_member"])}
              </Typography.LabelMd>
            )}
          </span>

          {isPost && (
            <>
              <NavLink to={postUrl} {...navLinkProps}>
                {t([i18nRoot, "posted"])}
              </NavLink>
              <Typography.LabelMd>{t([i18nRoot, "in"])}</Typography.LabelMd>
              <NavLink to={spaceUrl} {...navLinkProps}>
                {spaceName}
              </NavLink>
            </>
          )}
          {isComment && (
            <>
              <Typography.LabelMd>
                {t([i18nRoot, "commented"])}
              </Typography.LabelMd>
              <Typography.LabelMd>{t([i18nRoot, "in"])}</Typography.LabelMd>
              <NavLink
                to={`${postUrl}#comment_wrapper_${flaggedContentableId}`}
                {...navLinkProps}
              >
                {postTitle}
              </NavLink>
            </>
          )}
        </div>

        <div className="text-default flex items-center gap-1.5 font-semibold">
          <span className="flex h-4 items-center gap-1">
            <Icon className="!w-3" type="flag" size={24} />
            <Typography.LabelXs color="text-default">
              {reportsCount}
            </Typography.LabelXs>
          </span>
          <MiddleDot className="flex h-4 items-center" />
          <Typography.LabelXs color="text-default">
            {t([i18nRoot, "last_reported"], {
              time: getTimeAgo(reportedAt),
            })}
          </Typography.LabelXs>
        </div>
      </div>
    </div>
  );
};
