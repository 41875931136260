import { t } from "i18n-js";
import { Modal } from "@circle-react-shared/uikit/ModalV2";
import { Tab } from "@circle-react-shared/uikit/TabV2";
import { Button } from "@circle-react-uikit/Button";
import { CONTENT_TYPES_MAP } from "../constants";
import { ReportFlaggedContentable } from "./ReportFlaggedContentable";
import { ReportHeader } from "./ReportHeader";
import { ReportsList } from "./ReportsList";
import { useAproveRejectContent } from "./useAproveRejectContent";

const contentPortalId = "review-modal-content";
const i18nRoot = "settings.moderation.review_flagged_content_modal";

export interface ReportFlaggedContentModalProps {
  flaggedContentId: string;
  flaggedContentType: "flagged-content" | "post" | "comment";
  onClose: () => void | Promise<void> | Promise<unknown>;
  isOpen: boolean;
  hideDetailsTab?: boolean;
}

export const ReportFlaggedContentModal = ({
  flaggedContentId,
  flaggedContentType = "flagged-content",
  isOpen,
  onClose,
  hideDetailsTab = false,
}: ReportFlaggedContentModalProps) => {
  const {
    approveFlaggedContent,
    rejectFlaggedContent,
    flaggedContent,
    isApproving,
    isRejecting,
  } = useAproveRejectContent(flaggedContentId, flaggedContentType);

  const {
    author,
    flagged_contentable,
    reported_at: reportedAt,
    reports = [],
    status,
  } = flaggedContent ?? {};

  const {
    body_trix_content = "",
    type = "",
    tiptap_body,
    title,
    is_image_post: isImagePost,
    post_slug: postSlug,
    slug,
    space,
    id: flaggedContentableId,
  } = flagged_contentable ?? {};

  const contentType = type.toLowerCase();
  const reportsCount = reports?.length;

  const onClickApprove = async () => {
    approveFlaggedContent();
    await onClose();
  };

  const onClickReject = async () => {
    rejectFlaggedContent();
    await onClose();
  };

  if (
    contentType !== CONTENT_TYPES_MAP.POST &&
    contentType !== CONTENT_TYPES_MAP.COMMENT
  ) {
    return null;
  }

  const shouldShowApproveButton = status === null || status === "rejected";
  const shouldShowRejectButton = status === null || status === "approved";

  return (
    <Modal isOpen={isOpen} onClose={onClose} contentPortalId={contentPortalId}>
      <Modal.Overlay />
      <Modal.Content size="lg">
        <Modal.Header className="!py-0">
          <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-6">
            <Modal.CloseButton onClick={onClose} />
          </Modal.HeaderActions>
        </Modal.Header>

        <Modal.Body className="flex flex-col !p-0">
          <ReportHeader
            communityMember={author}
            contentType={contentType}
            reportedAt={reportedAt}
            reportsCount={reportsCount}
            postSlug={postSlug || slug}
            postTitle={title}
            space={space}
            flaggedContentableId={flaggedContentableId}
            onNavigate={onClose}
          />
          <Tab.Group className="!px-0">
            <Tab.List className="border-primary border-b px-8">
              {!hideDetailsTab && <Tab>{t([i18nRoot, "details"])}</Tab>}
              <Tab>
                {t([i18nRoot, "reports"])}
                <Tab.Counter>{reportsCount}</Tab.Counter>
              </Tab>
            </Tab.List>
            <Tab.Panels>
              {!hideDetailsTab && (
                <Tab.Panel className="px-8 py-6">
                  <ReportFlaggedContentable
                    trixBody={body_trix_content}
                    tiptapBody={tiptap_body}
                    postSlug={postSlug || slug}
                    spaceId={space?.id}
                    isImagePost={isImagePost}
                  />
                </Tab.Panel>
              )}
              <Tab.Panel>
                <ReportsList reports={reports} />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Modal.Body>
        <Modal.Footer className="border-primary flex items-center justify-end gap-4 border-t">
          <>
            {shouldShowApproveButton && (
              <Button
                variant="secondary"
                onClick={onClickApprove}
                disabled={isApproving}
              >
                {isApproving
                  ? t([i18nRoot, "approving"])
                  : t([i18nRoot, contentType, "approve"])}
              </Button>
            )}
            {shouldShowRejectButton && (
              <Button
                variant="danger"
                onClick={onClickReject}
                disabled={isRejecting}
              >
                {isRejecting
                  ? t([i18nRoot, "rejecting"])
                  : t([i18nRoot, contentType, "reject"])}
              </Button>
            )}
          </>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
