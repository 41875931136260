import {
  reactQueryGet,
  reactQueryPatch,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { UseModerationSettingsInfiniteQueryProps } from "./interfaces";

export interface ApiResource {
  id?: string;
  params?: UseModerationSettingsInfiniteQueryProps;
}

export const useModerationApi = () => {
  const list = ({ params = {} }: ApiResource) =>
    reactQueryGet(internalApi.flaggedContent.index({ params }));

  const get = async (
    id: string,
    type: "flagged-content" | "post" | "comment",
  ) => {
    const params =
      type === "post"
        ? { post_id: id }
        : type === "comment"
        ? { comment_id: id }
        : undefined;

    const response = await (params
      ? reactQueryGet(internalApi.flaggedContent.index({ params }))
      : reactQueryGet(internalApi.flaggedContent.show({ id })));

    if (
      response &&
      Array.isArray(response.records) &&
      response.records.length > 0
    ) {
      return response.records[0];
    }

    return response;
  };

  const approve = (id: string) =>
    reactQueryPatch(internalApi.flaggedContent.approve({ id }));

  const reject = (id: string) =>
    reactQueryPatch(internalApi.flaggedContent.reject({ id }));

  return {
    list,
    get,
    approve,
    reject,
  };
};
