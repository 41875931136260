import { usePostResource } from "@/react/hooks/posts/usePostResource";
import type { ImagePost } from "@/react/types";
import {
  CurrentPostContextProvider,
  CurrentSpaceContextProvider,
} from "@circle-react/contexts";
import { PostsDetailsContextProvider } from "@circle-react/contexts/postsDetailsContext";
import { Loader } from "@circle-react-uikit/Loader";
import { Carousel } from "./Carousel";

export const StandaloneCarousel = ({ postSlug, spaceId }: any) => {
  const { isLoading, post }: { isLoading: boolean; post: ImagePost } =
    usePostResource({
      spaceId,
      postSlug,
    });
  if (isLoading || !post) return <Loader />;
  return (
    <CurrentSpaceContextProvider spaceId={spaceId}>
      <PostsDetailsContextProvider perPagePostIds={[[post.id]]}>
        <CurrentPostContextProvider post={post}>
          <Carousel />
        </CurrentPostContextProvider>
      </PostsDetailsContextProvider>
    </CurrentSpaceContextProvider>
  );
};
